import { fileRef } from './firestore';
import { getTimestamp } from './firebase';
import { storage } from './storage';
import { randomString } from '../utils/random';

class FFile {
  constructor() {
    this.ref = fileRef;
    this.storage = storage;
  }

  uploadFile({ file, user }) {
    return new Promise((resolve, reject) => {
      const path = `/${user}/${randomString(10)}_${file.name}`;

      const ref = this.storage.ref().child(path);

      ref
        .put(file)
        .then(() => {
          resolve(path);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getDownloadURL(path) {
    return this.storage
      .ref()
      .child(path)
      .getDownloadURL();
  }

  createFile({ folderId, name, user, path, url, metadata, size, parcelId, parcelNumber }) {
    const data = {
      name: '',
      path: '',
      url: '',
      type: '',
      size: 0,
      user: null,
      metaData: null,
      parcelId: null,
      parcelNumber: null,
      createdAt: getTimestamp(),
      updatedAt: getTimestamp()
    };

    if (name) {
      data.name = name;
    }
    if (folderId) {
      data.folderId = folderId;
    }
    if (user) {
      data.user = user;
    }
    if (path) {
      data.path = path;
    }
    if (url) {
      data.url = url;
    }
    if (metadata) {
      data.metadata = metadata;
    }
    if (size) {
      data.size = size;
    }
    if (parcelId) {
      data.parcelId = parcelId;
    }
    if (parcelNumber) {
      data.parcelNumber = parcelNumber;
    }

    return this.ref.add(data);
  }

  listFiles({ user }) {
    return this.ref.where('user', '==', user).orderBy('name', 'asc');
  }

  updateMetadata(path, { description, feature, name, parcelId, user }) {
    let customMeta = {
      customMetadata: {
        description: description,
        feature: feature,
        filename: name,
        parcelId,
        user
      }
    };
    const ref = storage.ref().child(path);
    return ref.updateMetadata(customMeta);
  }
}

export default FFile;
