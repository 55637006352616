import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Upload File "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('div',{staticClass:"my-2"},[_c(VAlert,{attrs:{"type":"info","text":""}},[_vm._v(" If you want to upload file to a specific parcel and feature, please go to those areas of Peeps. ")])],1),_c('UploadForm',{on:{"upload":function($event){return _vm.$emit('upload')}}})],1)],1)],1),_c(VBtn,{staticClass:"text-capitalize",attrs:{"large":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Upload File")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }